import React from 'react';

interface IconProps {
  className?: string;
}

export default function CaretRightSkipLast(props: IconProps) {
  return (
    <svg {...props} viewBox="0 0 14 12" role="img" className={props.className}>
      <title>First Page</title>

      <path
        d="M7.70710678,4.29289322 L13.7071068,10.2928932 C14.0976311,10.6834175 14.0976311,11.3165825 13.7071068,11.7071068 C13.3165825,12.0976311 12.6834175,12.0976311 12.2928932,11.7071068 L7,6.41421356 L1.70710678,11.7071068 C1.31658249,12.0976311 0.683417511,12.0976311 0.292893219,11.7071068 C-0.0976310729,11.3165825 -0.0976310729,10.6834175 0.292893219,10.2928932 L6.29289322,4.29289322 C6.68341751,3.90236893 7.31658249,3.90236893 7.70710678,4.29289322 Z M13.125,0 C13.6082492,0 14,0.44771525 14,1 C14,1.55228475 13.6082492,2 13.125,2 L0.875,2 C0.391750844,2 0,1.55228475 0,1 C0,0.44771525 0.391750844,0 0.875,0 L13.125,0 Z"
        id="path-1"
      ></path>
    </svg>
  );
}
