import React, { ChangeEvent, FormEvent, useCallback, useState, useEffect } from 'react';

import './SearchBox.scss';

import SearchIcon from '../../svg/search.svg';
import CloseIcon from '../Icons/CloseIcon';
import { translateLanguage } from '../utils/UtilityFunctions';
import SearchBoxQuickSearch from './SearchBoxQuickSearch';
import { SearchResult } from 'src/search';

export interface SearchBoxProps {
  onSearch: (arg: string) => void;
  onChangeSearch?: (arg: string) => void;
  placeholder?: string;
  language: string;
  pageContextLanguage: string;
  quickSearchResults?: SearchResult[];
  initialValue?: string;
}

export default function SearchBox({
  placeholder = 'Search Here',
  onSearch,
  onChangeSearch,
  pageContextLanguage,
  quickSearchResults,
  initialValue,
}: SearchBoxProps) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  useEffect(() => {
    if (initialValue) {
      setSearchTerm(initialValue);
    }
  }, [initialValue]);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSearch(searchTerm);
    },
    [searchTerm]
  );

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    if (onChangeSearch) {
      onChangeSearch(e.target.value);
    }
  }, []);

  const quickSearch = (term: string) => {
    setSearchTerm(term);
    onSearch(term);
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
    onSearch('');
    if (onChangeSearch) {
      onChangeSearch('');
    }
  };

  return (
    <form className="searchbox" onSubmit={onSubmit}>
      <div className="searchbox__container">
        <div className="searchbox__input-container">
          <SearchIcon className="searchbox__input-container__input-icon" />

          <input
            type="text"
            className={
              'searchbox__input-container__input' +
              (quickSearchResults && quickSearchResults.length > 0 ? ' quick-search' : '')
            }
            name="search"
            placeholder={placeholder}
            autoComplete={'off'}
            onChange={onChange}
            value={searchTerm}
            data-testid="search-box"
            aria-label={translateLanguage(pageContextLanguage.toLowerCase(), 'Search', 'Buscar')}
          />

          <button
            className="searchbox__input-container__reset-icon"
            data-testid="clear-search"
            type="reset"
            onClick={clearSearchTerm}
            aria-label={translateLanguage(
              pageContextLanguage.toLowerCase(),
              'Clear Search',
              'Borrar la Busqueda'
            )}
          >
            <CloseIcon
              title={translateLanguage(
                pageContextLanguage.toLowerCase(),
                'Clear Search',
                'Borrar la Busqueda'
              )}
              className="rest-icon__icon"
            />
          </button>
        </div>

        <div className="searchbox__quick-results">
          {quickSearchResults && quickSearchResults.length > 0 && (
            <SearchBoxQuickSearch
              results={quickSearchResults}
              searchTerm={searchTerm}
              setSearchTerm={quickSearch}
            />
          )}
        </div>
      </div>

      <button className="searchbox__search-btn" aria-label="Search" data-testid="search-submit">
        {translateLanguage(pageContextLanguage.toLowerCase(), 'Search', 'Buscar')}
      </button>
    </form>
  );
}
