import React, { PropsWithChildren, ReactElement } from 'react';
import { Link } from 'gatsby';

import CaretRight from '../Icons/CaretRight';
import CaretLeft from '../Icons/CaretLeft';
import CaretRightSkipLast from '../Icons/CaretRightSkipLast';
import CaretLeftSkipFirst from '../Icons/CaretLeftSkipFirst';

import './Pagination.scss';

interface PageinationProps {
  currentLanguage: string;
  totalItems: number;
  pageSize: number;
  currentPage: number;
  createLinkUrl: (page: number) => string;
  onClick?: (page: number) => any;
}

/**
 * Render both sets of pagination links and use css to show and hide them based on screen size
 */
export default function Pagination(props: PageinationProps) {
  return (
    <>
      {props.totalItems ? (
        <>
          <div className="pagination desktop">
            <PaginationLinks {...props} visibleLinks={5} />
          </div>

          <div className="pagination mobile">
            <PaginationLinks {...props} visibleLinks={4} />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

interface PageinationLinkProps {
  pageNumber: number;
  createLinkUrl: (page: number) => string;
  ariaLabel: string;
  onClick?: (page: number) => any;
  className?: string;
  currentPage?: number;
}

function PaginationLink({
  pageNumber,
  createLinkUrl,
  ariaLabel,
  onClick,
  children,
  className,
  currentPage,
}: PropsWithChildren<PageinationLinkProps>) {
  const active = currentPage === pageNumber;
  return (
    <>
      {onClick ? (
        <button
          onClick={() => onClick(pageNumber)}
          aria-label={ariaLabel}
          key={pageNumber}
          className={`link-btn ${className} ${active ? 'active' : ''}`}
          data-testid="page-link"
        >
          {children}
        </button>
      ) : (
        <Link
          to={createLinkUrl(pageNumber)}
          data-testid="page-link"
          key={pageNumber}
          aria-label={ariaLabel}
          className={`${className}  ${active ? 'active' : ''}`}
        >
          {children}
        </Link>
      )}
    </>
  );
}

interface PageinationLinksProps {
  currentLanguage: string;
  totalItems: number;
  pageSize: number;
  currentPage: number;
  createLinkUrl: (page: number) => string;
  visibleLinks?: number;
  onClick?: (page: number) => any;
}

export function PaginationLinks({
  currentLanguage,
  totalItems,
  pageSize,
  currentPage,
  createLinkUrl,
  visibleLinks = 5,
  onClick,
}: PageinationLinksProps) {
  const totalPages = Math.ceil(totalItems / pageSize);
  const linkCount = visibleLinks - 1;
  const prevLinks: ReactElement[] = [];
  const nextLinks: ReactElement[] = [];

  // Add up to 4 "next" links including the current page
  for (let i = 0; i < linkCount; i++) {
    const pageNum = currentPage + i;

    if (pageNum < totalPages) {
      nextLinks.push(
        <PaginationLink
          currentPage={currentPage}
          pageNumber={pageNum}
          createLinkUrl={createLinkUrl}
          ariaLabel={`Navigate to page ${pageNum}`}
          onClick={onClick}
          key={pageNum}
        >
          <span>{pageNum}</span>
        </PaginationLink>
      );
    }
  }

  // Add up to 4 next links (including current page)
  // subtract the number of previous links
  for (let i = linkCount - nextLinks.length; i > 0; i--) {
    const pageNum = currentPage - i;

    if (pageNum > 0) {
      prevLinks.push(
        <PaginationLink
          currentPage={currentPage}
          pageNumber={pageNum}
          createLinkUrl={createLinkUrl}
          ariaLabel={`Navigate to page ${pageNum}`}
          onClick={onClick}
          key={pageNum}
        >
          <span>{pageNum}</span>
        </PaginationLink>
      );
    }
  }

  return (
    <>
      {currentPage !== 1 ? (
        <>
          <PaginationLink
            currentPage={currentPage}
            pageNumber={1}
            createLinkUrl={createLinkUrl}
            ariaLabel="Navigate to first page"
            onClick={onClick}
            className="pagination__first"
          >
            <CaretLeftSkipFirst className="caret-skip caret-first caret-enabled" />
            <span className="d-none-mobile">
              {currentLanguage === 'en' ? ' First' : ' Primero'}
            </span>
          </PaginationLink>

          <PaginationLink
            currentPage={currentPage}
            pageNumber={currentPage - 1}
            createLinkUrl={createLinkUrl}
            ariaLabel="Navigate to previous page"
            onClick={onClick}
            className="pagination__previous"
          >
            <CaretLeft className="caret-left caret-first caret-enabled" />
            <span className="d-none-mobile">
              {currentLanguage === 'en' ? ' Previous' : ' Anterior'}
            </span>
          </PaginationLink>
        </>
      ) : (
        <>
          <span className="pagination__first--placeholder" aria-hidden="true">
            <CaretLeftSkipFirst className="caret-skip caret-disabled" />
            <span className="d-none-mobile">
              {currentLanguage === 'en' ? ' First' : ' Primero'}
            </span>
          </span>
          <span className="pagination__previous--placeholder" aria-hidden="true">
            <CaretLeft className="caret-left caret-disabled" />
            <span className="d-none-mobile">
              {currentLanguage === 'en' ? ' Previous' : ' Anterior'}
            </span>
          </span>
        </>
      )}

      {/* Numerical pagination links -- desktop only */}
      <div className="pagination__links d-none-mobile">
        {prevLinks}

        {nextLinks}

        {totalPages > 1 && <div className="ellipsis">...</div>}

        <PaginationLink
          currentPage={currentPage}
          pageNumber={totalPages}
          createLinkUrl={createLinkUrl}
          ariaLabel={`Navigate to page ${totalPages}`}
          onClick={onClick}
        >
          {totalPages}
        </PaginationLink>
      </div>

      {/* Numerical pagination links -- mobile only */}
      <div className="pagination__links d-none-desktop">
        {prevLinks}

        {nextLinks}

        {totalPages > 1 && <div className="ellipsis">...</div>}
        <PaginationLink
          currentPage={currentPage}
          pageNumber={totalPages}
          key={currentPage}
          ariaLabel={`Navigate to page ${totalPages}`}
          onClick={onClick}
          createLinkUrl={createLinkUrl}
        >
          {totalPages}
        </PaginationLink>
      </div>

      {currentPage < totalPages ? (
        <>
          <PaginationLink
            currentPage={currentPage}
            className="pagination__next"
            createLinkUrl={createLinkUrl}
            ariaLabel="Navigate to next page"
            pageNumber={currentPage + 1}
            onClick={onClick}
          >
            <span className="d-none-mobile">
              {currentLanguage === 'en' ? 'Next ' : 'Siguiente '}
            </span>
            <CaretRight className="caret-right caret-enabled" />
          </PaginationLink>

          <PaginationLink
            currentPage={currentPage}
            pageNumber={totalPages}
            createLinkUrl={createLinkUrl}
            className="pagination__last"
            ariaLabel="Navigate to last page"
            onClick={onClick}
          >
            <span className="d-none-mobile">{currentLanguage === 'en' ? 'Last ' : 'Ultimo '}</span>
            <CaretRightSkipLast className="caret-enabled caret-skip" />
          </PaginationLink>
        </>
      ) : (
        <>
          <span className="pagination__next--placeholder" aria-hidden="true">
            <span className="d-none-mobile">
              {currentLanguage === 'en' ? 'Next ' : 'Siguiente '}
            </span>
            <CaretRight className="caret-right caret-disabled" />
          </span>
          <span className="pagination__last--placeholder" aria-hidden="true">
            <span className="d-none-mobile">{currentLanguage === 'en' ? 'Last ' : 'Ultimo '}</span>
            <CaretRightSkipLast className="caret-skip caret-disabled" />
          </span>
        </>
      )}
    </>
  );
}
