import React from 'react';

interface IconProps {
  className?: string;
}

export default function CaretLeft(props: IconProps) {
  return (
    <svg {...props} viewBox="0 0 14 8" role="img" className={props.className}>
      <title>Previous Page</title>

      <path
        d="M7,5.58578644 L1.70710678,0.292893219 C1.31658249,-0.0976310729 0.683417511,-0.0976310729 0.292893219,0.292893219 C-0.0976310729,0.683417511 -0.0976310729,1.31658249 0.292893219,1.70710678 L6.29289322,7.70710678 C6.68341751,8.09763107 7.31658249,8.09763107 7.70710678,7.70710678 L13.7071068,1.70710678 C14.0976311,1.31658249 14.0976311,0.683417511 13.7071068,0.292893219 C13.3165825,-0.0976310729 12.6834175,-0.0976310729 12.2928932,0.292893219 L7,5.58578644 Z"
        id="path-1"
      ></path>
    </svg>
  );
}
