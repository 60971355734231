import { Metatag } from '../../fragments/texas-node';

export function cleanPaginatedMetatags(metaTags: Metatag[], pageNumber: number) {
  return metaTags.map((tag) => {
    if (pageNumber > 1) {
      if (tag.key === 'title') {
        return { ...tag, value: `Page ${pageNumber} for ${tag.value}` };
      } else if (tag.key === 'description') {
        return { ...tag, value: '' };
      }
    }

    return tag;
  });
}
