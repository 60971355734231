import React from 'react';

import xss from 'xss';

import { defaultBreadcrumb, defaultBreadcrumbSpanish } from '../../fragments/texas-node';
import Breadcrumbs from '../Generics/Breadcrumbs';

import './Hero.scss';

interface HeroProps {
  title: string;
  description: string;
  language: string;
  link?: {
    title: string;
    uri: string;
    url: {
      path: string;
    };
  };
}

export default function Hero({ title, description, language, link }: HeroProps) {
  const htmlContent = `${description}`;
  const sanitizedContent = xss(htmlContent);
  return (
    <div className="wrapper">
      <div className="container container--column">
        <Breadcrumbs
          breadcrumb={language == 'en' ? defaultBreadcrumb : defaultBreadcrumbSpanish}
          currentPageTitle={title}
        />
        <div className="w-2/3">
          <h1 className="hero-dir__title">{title}</h1>
          <div
            className="hero-dir__description pb-5"
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          />
          {link && (
            <a href={link.url.path} className="hero-dir__link">
              {link.title}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
