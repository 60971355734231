import React from 'react';

interface IconProps {
  className?: string;
}

export default function CaretRightSkipLast(props: IconProps) {
  return (
    <svg {...props} viewBox="0 0 14 12" role="img" className={props.className}>
      <title>Last Page</title>

      <path
        d="M13.125,10 C13.6082492,10 14,10.4477153 14,11 C14,11.5522847 13.6082492,12 13.125,12 L0.875,12 C0.391750844,12 0,11.5522847 0,11 C0,10.4477153 0.391750844,10 0.875,10 L13.125,10 Z M0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 L1.70710678,0.292893219 L7,5.58578644 L12.2928932,0.292893219 C12.6834175,-0.0976310729 13.3165825,-0.0976310729 13.7071068,0.292893219 C14.0976311,0.683417511 14.0976311,1.31658249 13.7071068,1.70710678 L13.7071068,1.70710678 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 L6.29289322,7.70710678 L0.292893219,1.70710678 C-0.0976310729,1.31658249 -0.0976310729,0.683417511 0.292893219,0.292893219 Z"
        id="path-1"
      ></path>
    </svg>
  );
}
