import React from 'react';

interface IconProps {
  className?: string;
}

export default function CaretRight(props: IconProps) {
  return (
    <svg {...props} viewBox="0 0 8 14" role="img" className={props.className}>
      <title>Next Page</title>

      <path
        d="M5.58578644,7 L0.292893219,12.2928932 C-0.0976310729,12.6834175 -0.0976310729,13.3165825 0.292893219,13.7071068 C0.683417511,14.0976311 1.31658249,14.0976311 1.70710678,13.7071068 L7.70710678,7.70710678 C8.09763107,7.31658249 8.09763107,6.68341751 7.70710678,6.29289322 L1.70710678,0.292893219 C1.31658249,-0.0976310729 0.683417511,-0.0976310729 0.292893219,0.292893219 C-0.0976310729,0.683417511 -0.0976310729,1.31658249 0.292893219,1.70710678 L5.58578644,7 Z"
        id="path-1"
      ></path>
    </svg>
  );
}
