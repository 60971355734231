import React from 'react';
import xss from 'xss';

import { SearchResult } from '../../search';
import SearchIcon from '../Icons/SearchIcon';

import styles from './SearchBoxQuickSearch.module.scss';

export interface SearchBoxQuickSearchProps {
  results: SearchResult[];
  searchTerm: string;
  setSearchTerm?: (arg: string) => void;
}

function boldSearch(term: string, label: string) {
  const termUpperCase = term.toUpperCase();
  const labelUpperCase = label.toUpperCase();

  const termIndex = labelUpperCase.indexOf(termUpperCase);

  if (termIndex >= 0) {
    const bold = label.substring(termIndex, term.length + termIndex);
    const normalFirst = label.substring(0, termIndex);
    const normalLast = label.substring(termIndex + term.length);

    const string = normalFirst + '<strong>' + bold + '</strong>' + normalLast;

    return <div dangerouslySetInnerHTML={{ __html: xss(string) }}></div>;
  } else {
    return <div dangerouslySetInnerHTML={{ __html: xss(label) }}></div>;
  }
}

export default function SearchBoxQuickSearch({
  results,
  searchTerm,
  setSearchTerm,
}: SearchBoxQuickSearchProps) {
  function onClick(label: string) {
    if (setSearchTerm) {
      setSearchTerm(label);
    }
  }
  let resultsMax = results;
  if (results.length > 10) {
    resultsMax = results.slice(0, 10);
  }

  return (
    <ul className={styles.quickSearchResults}>
      {resultsMax.map((result) => (
        <li key={result.id}>
          <button type="button" onClick={() => onClick(result.entityLabel)}>
            <SearchIcon className={styles.searchIcon} />
            {boldSearch(searchTerm, result.entityLabel)}
          </button>
        </li>
      ))}
    </ul>
  );
}
